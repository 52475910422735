import 'whatwg-fetch';

class HttpService {
	getCompetitionNames = () => {
		var promise = new Promise((resolve, reject) => {
			fetch(process.env.REACT_APP_API_SERVER, {mode:'cors'},)
			//fetch('http://ymeramees.no-ip.org:3004/rapla', {mode:'cors'},)
				.then(response => {
					resolve(response.json());
				//	return response.json();
				})
		});
		return promise;
	}

	getEventNames = (competitionId) => {
		var promise = new Promise((resolve, reject) => {
			fetch(process.env.REACT_APP_API_SERVER + '/' + competitionId, {mode:'cors'},)
			//fetch('http://ymeramees.no-ip.org:3004/rapla', {mode:'cors'},)
				.then(response => {
					resolve(response.json());
				//	return response.json();
				})
		});
		return promise;
	}

	getEvent = (competitionId, eventId) => {
		var promise = new Promise((resolve, reject) => {
			fetch(process.env.REACT_APP_API_SERVER + '/' + competitionId + '/' + eventId,
					{mode:'cors'},)
				.then(response => {
					resolve(response.json());
				})
		});
		return promise;
	}
}

export default HttpService;
