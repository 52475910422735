import React, { Component } from 'react';
//import logo from './logo.svg';
import logo from './Finaal.ico';
import './App.css';

// Components
import HttpService from './services/http-service.js';

const http = new HttpService();
const CompetitorsTable = require('ts-react-json-table');
//var competitionNames;

function competitionChoice(competitionNames, handleChange) {
	console.log("competitionNames: " + JSON.stringify(competitionNames));
	if(competitionNames !== undefined) {
		return (
			<div className="competitionChoice">
				<select onChange={handleChange}>
					{competitionNames.map((competition) => {
						return (
							<option
								value={competition.id}
								key={competition.id}>
								{competition.competitionName + " - " + competition.timeAndPlace}
							</option>
						);
					})}
				</select>
			</div>
		);
	}
}

function eventButton(eventObj, handleClick) {
	return (
		<li><button className="eventButton" key={eventObj.id} id={eventObj.id} onClick={handleClick}>
			{eventObj.eventName}
		</button></li>
	);
}

function eventButtonRow(eventNames, handleClick) {
	console.log("eventNames: " + JSON.stringify(eventNames));
	if(eventNames !== undefined) {
		return (
			<ul className="buttonRow">
				{eventNames.map((eventObj) => {
				return (eventButton(eventObj, handleClick))
				//console.log(element);
			})}
			</ul>
		);
	}
}

class App extends Component {

	constructor(props) {
		super(props);

		this.state = {competitionNames: []};
		this.state = {eventNames: []};
		this.state = {activeCompetition: ''};
		this.state = {activeEvent: ''};
		this.state = {competitors: []};
		this.state = {columns: []};

		this.loadInitialData = this.loadInitialData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.loadEventNames = this.loadEventNames.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.createCompetitorColumns = this.createCompetitorColumns.bind(this);
		this.createTeamColumns = this.createTeamColumns.bind(this);

		this.loadInitialData();
	}

	loadInitialData = () => {
		//var self = this;
		//var stringData;
		http.getCompetitionNames().then(data => {
			this.setState({
				competitionNames: data,
				activeCompetition: data[0].id,
			}, () => {
				this.loadEventNames();
			});
			//console.log("data.stringify: " + JSON.stringify(data));
			//competitionNames.forEach((name) => {
			//	console.log(name.competitionName);
			//});
		}, err => {
			console.log("Error!: " + err);
		});
		//return competitionChoice(["Raplamaa", "Eesti MV"])
	}

	handleChange(event) {
		this.setState({activeCompetition: event.target.value}, () => {
			this.loadEventNames();
		});
	}

	handleClick(event) {
		console.log("handleClick event.target.id: " + event.target.id);
		this.setState({activeEvent: event.target.id}, () => {
			this.loadEvent();
		});
	}

	createCompetitorColumns = (competitorRow) => {
		let columns = [
			{key: 'rank', label: 'Koht', cell: 'rank'},
			{key: 'firstName', label: 'Eesnimi', cell: function(item, columnKey) {
				return <div style={{width: '100%', textAlign: 'left'}}>{item.firstName}</div>}},
			{key: 'lastName', label: 'Perekonnanimi', cell: function(item, columnKey) {
				return <div style={{width: '100%', textAlign: 'left'}}>{item.lastName}</div>}},
			{key: 'club', label: 'Klubi', cell: function(item, columnKey) {
				return <div style={{width: '100%', textAlign: 'left'}}>{item.club}</div>}}
			]

		if (competitorRow.subtotals != null) {
		for(let i = 0; i < competitorRow.subtotals.length; i++) {
			for(let j = 0; j < competitorRow.subtotals[i].series.length; j++) {
				let seriesString = 'series' + i + '_' + j;
				columns.push({key: seriesString, label: ' ', cell: seriesString, group: competitorRow.subtotals[i].label});
			}
			if (competitorRow.subtotals.length > 1){
				let subtotalString = 'subtotal' + i
				columns.push({key: 'subtotal' + i, label: ' ', cell: function(item, columnKey) {
					return <div style={{width: '100%', paddingRight: '10px'}}><strong>{item[subtotalString]}</strong></div>},
					group: competitorRow.subtotals[i].label});
			}
		}
	} else {
			for(let j = 0; j < competitorRow.series.length; j++) {
				let seriesString = 'series' + j;
				columns.push({key: seriesString, label: ' ', cell: seriesString});
			}
		}

		columns.push(
			{key: 'totalResult', label: 'Summa', cell: function(item, columnKey) {
				return <div style={{width: '100%'}}><strong>{item.totalResult}</strong></div>}},
			{key: 'finals', label: 'Finaal', cell: 'finals'},
			{key: 'remarks', label: ' ', cell: 'remarks'}
		)

		return columns;
	}

	createTeamColumns = (teamRow) => {
		let columns = [
			{key: 'rank', label: 'Koht', cell: 'rank'},
			{key: 'teamName', label: 'Nimi', cell: function(item, columnKey) {
				return <div style={{width: '100%', textAlign: 'left'}}>{item.teamName}</div>}},
			{key: 'firstName', label: 'Eesnimi', cell: function(item, columnKey) {
				return <div style={{width: '100%', textAlign: 'left'}}>{item.firstName}</div>}},
			{key: 'lastName', label: 'Perekonnanimi', cell: function(item, columnKey) {
				return <div style={{width: '100%', textAlign: 'left'}}>{item.lastName}</div>}},
			{key: 'result', label: 'Tulemus', cell: function(item, columnKey) {
				return <div style={{width: '100%'}}>{item.result}</div>}}
			]

		columns.push(
			{key: 'totalResult', label: 'Summa', cell: function(item, columnKey) {
				return <div style={{width: '100%'}}><strong>{item.totalResult}</strong></div>}},
			{key: 'remarks', label: ' ', cell: 'remarks'}
		)

		return columns;
	}

	loadEventNames = () => {
		http.getEventNames(this.state.activeCompetition).then(data => {
			this.setState({eventNames: data});
		}, err => {
			console.log("Error!");
		});
	}

	loadEvent = () => {
		console.log("activeCompetition: " + this.state.activeCompetition + ", activeEvent: " + this.state.activeEvent);
		http.getEvent(this.state.activeCompetition, this.state.activeEvent).then(data => {
			let competitorsArray = [];
			let index = 1;
			if (data['teams'] !== undefined && data['teams'].length > 0) {
				data['teams'].forEach((teamObj) => {
					let team = {};
					team['rank'] = index++;
					team['teamName'] = teamObj.teamName;
					team['totalResult'] = teamObj.totalResult;
					team['remarks'] = teamObj.remarks;
					console.log("team: " + JSON.stringify(team));
					competitorsArray.push(team);
					teamObj.teamMembers.forEach((memberObj) => {
						let member = {};
						member['firstName'] = memberObj.firstName;
						member['lastName'] = memberObj.lastName;
						member['club'] = memberObj.club;
						member['result'] = memberObj.result;
						competitorsArray.push(member);
					})
				});
				this.setState({competitors: competitorsArray});

				if (data.teams.length > 0)
					this.setState({columns: this.createTeamColumns(data.teams[0])});

				console.log("teams: " + JSON.stringify(competitorsArray));
			} else {
				data['competitors'].forEach((competitorObj) => {
					let competitor = {};
					competitor['rank'] = index++;
					if(competitorObj.remarks != null) {
						if (competitorObj.remarks.toLowerCase().includes("v.a")) {
							competitor['rank'] = "V.A."
						}
					}
					competitor['firstName'] = competitorObj.firstName;
					competitor['lastName'] = competitorObj.lastName;
					competitor['club'] = competitorObj.club;
					if (competitorObj.subtotals != null) {
						for(let i = 0; i < competitorObj.subtotals.length; i++) {
							for(let j = 0; j < competitorObj.subtotals[i].series.length; j++) {
								competitor['series' + i + '_' + j] = competitorObj.subtotals[i].series[j].seriesSum;
							}
							competitor['subtotal' + i] = competitorObj.subtotals[i].subtotal
							competitor['subtotalLabel' + i] = competitorObj.subtotals[i].label
						}
					} else {
						for(let j = 0; j < competitorObj.series.length; j++) {
							competitor['series' + j] = competitorObj.series[j].seriesSum;
						}
					}

					competitor['totalResult'] = competitorObj.totalResult;
					if(competitorObj.finals === "Fin") {
						competitor['finals'] = "";
					} else {
						competitor['finals'] = competitorObj.finals;
					}
					competitor['remarks'] = competitorObj.remarks;
					console.log("competitor: " + JSON.stringify(competitor));
					competitorsArray.push(competitor);
				});
				this.setState({competitors: competitorsArray});

				if (data.competitors.length > 0)
					this.setState({columns: this.createCompetitorColumns(data.competitors[0])});

				console.log("competitors: " + JSON.stringify(competitorsArray));
			}
		}, err => {
			console.log("Error!");
		});
	}

  render() {
	console.log("activeCompetition: " + this.state.activeCompetition);
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
		  <h1 className="App-title">Tere tulemast Protokollitaja tulemuste lehele</h1>
        </header>
	<div className="container-fluid App-main">
		<div>
			{competitionChoice(this.state.competitionNames, this.handleChange)}
		</div>
		<div>
			{eventButtonRow(this.state.eventNames, this.handleClick/*["Harjutus", "Puss", "Pustol"]*/)}
		</div>
		<div className="competitorsTable">
			<CompetitorsTable rows={this.state.competitors} columns={this.state.columns} />
		</div>
	</div>
      </div>
    );
  }
}

export default App;
